import { setCookie } from '../utils';

// When persisted cookies are received from ReactNative, update the cookies in the browser
// See mobile-app/src/logic/messageHandler.tsx and mysite/static/react-app/src/services/cookie.ts for
// more details
export const setCookieFromReactNativeInWebview = (key: string, value: string) => {
  const [cookieValue, expires] = value.split(';');
  const cookieExpires = expires ? new Date(expires.split('=')[1]) : undefined;
  setCookie(key, cookieValue, cookieExpires);
};
