import { EventBusEventName } from '../../react-app/src/enums/event-bus-event-name';

export const webViewNavigationEventService = {
  dispatchEventToRestorePreviousUrlsInWebView: (): void => {
    document.dispatchEvent(new CustomEvent(EventBusEventName.WEBVIEW_SHOULD_RELOAD_PREVIOUS_URLS));
  },

  setUpListenerForWebViewToListenForPreviousUrlsUpdate: (params: {
    callback?: () => void;
  }): void => {
    const { callback } = params;
    document.addEventListener(EventBusEventName.WEBVIEW_SHOULD_RELOAD_PREVIOUS_URLS, () => {
      callback?.();
    });
  },

  removeListenerForWebViewToListenForPreviousUrlsUpdate: (params: {
    callback?: () => void;
  }): void => {
    const { callback } = params;
    document.removeEventListener(EventBusEventName.WEBVIEW_SHOULD_RELOAD_PREVIOUS_URLS, () =>
      callback?.()
    );
  },
};
