import { getCookie } from './utils';

const registerPushToken = (body) => {
  fetch('/push/api/device/expo', {
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'same-origin',
    method: 'post',
    body: JSON.stringify(body),
  });
};

export default registerPushToken;
