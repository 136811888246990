import { copyText } from '../utils';
/**
 * check if in Portrait view
 * @returns boolean
 */
export const isPortraitView = () => window.matchMedia('(orientation: portrait)').matches;

/**
 * Larger screens can be mobile devices but for our current use case, we using this for < 576px
 */
export const isMobileDevicePortrait = () =>
  window.matchMedia('screen and (max-width:576px)').matches && isPortraitView();

/**
 * check if in Landscape view
 * @returns boolean
 */
export const isLandscapeView = () => window.matchMedia('(orientation: landscape)').matches;

// Detects if device is on iOS using User Agent.
//
// See https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isAppleDevice = userAgent.includes('macintosh');
  const isTouchScreen = navigator.maxTouchPoints >= 1;

  const isIpadAltTest = isAppleDevice && isTouchScreen;

  return /iphone|ipad|ipod/.test(userAgent) || isIpadAltTest;
};

// Detects if device is on Android using User Agent
export const isAndroid = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /android/.test(userAgent);
};

// This has inconsistent hyphenation for morphmarket and coldblooded that's difficult to undo due to the need to coordinate with coldblooded's site.
export const isAppUserAgent = () =>
  navigator.userAgent.includes('morphmarket-web-view') ||
  navigator.userAgent.includes('coldblooded-webview');

// Detects if the code is being executed in the Facebook Mobile browser, its embedded browser
const isFacebookBrowser = () => {
  const { userAgent } = navigator;
  return userAgent.includes('FBAN') || userAgent.includes('FBAV');
};

/**
 * Whether or not it is the Morphmarket Android or iOS standalone app (real device or simulator)
 */
export const isApp = () => !isFacebookBrowser() && isAppUserAgent();

// This is useful for getting an integer version number for the app so easy
// comparisons. By convention, non-apps are 0.
export const buildVersion = () => {
  if (!isApp()) {
    return 0;
  }
  return Number(window.MOBILE_APP_VERSION.split('-')[1]);
};

/*
 * Whether or not it is a mobile device < 576 px and not the installed app
 */
export const isMobileNonApp = () =>
  !isApp() && window.matchMedia('screen and (max-width:576px)').matches;

export const isNativeMobilePortrait = () =>
  isApp() && window.matchMedia('(orientation: portrait)').matches;

// borrowed from this https://stackoverflow.com/a/21070876
export class TwoWayMap {
  constructor(map) {
    this.map = map;
    this.reverseMap = {};
    /* eslint-disable-next-line no-restricted-syntax, guard-for-in */
    for (const key in map) {
      const value = map[key];
      this.reverseMap[value] = key;
    }
  }

  getMap() {
    return this.map;
  }

  get(key) {
    return this.map[key];
  }

  revGet(key) {
    return this.reverseMap[key];
  }

  getKeys() {
    return this.map.keys;
  }
}

export const toggleSpinner = (shouldShow = true) => {
  if (shouldShow) {
    // Don't use setTimeout for delay on showing spinner b/c iOS ignores it when page navigation happens
    // Instead, use CSS animation delay like we are doing on the .loading-spinner class
    $('#loadingSpinner').addClass('loading-spinner');
  } else {
    $('#loadingSpinner').removeClass('loading-spinner');
  }
};

export const addClickHandlerToInvokeSpinner = () => {
  // show loading spinner on link click if it's a real link
  // 1. Has href that is not "#"
  // 2. Not a javascript function
  // 3. Not a data-toggle, i.e. from Bootstrap

  // 4. Not a data-no-spinner, used in messenger
  $(
    'a:not([href^="#"]):not([href^="javascript:"]):not([data-toggle]):not([href=""]):not([data-no-spinner])'
  ).on('click', function () {
    // if the <a> tag doesn't have an href attribute, don't toggle the spinner on click
    // it would be much better to add this in the selector above as a :not but jquery doesn't appear to allow for that
    if (this.hasAttribute('href')) {
      toggleSpinner();
    }
  });
};

export const copyVersionNumberInfo = ($elemToCopyContents, $containerClicked) => {
  const usernameString = `username: ${window.USERNAME ? window.USERNAME : 'user_not_logged_in'}`;
  const userAgentString = `userAgent:  ${navigator.userAgent}`;
  const hrefString = `href: ${window.location.href}`;

  const stringToCopy = `${$elemToCopyContents.text()} || ${usernameString} || ${userAgentString} || ${hrefString}`;
  copyText(stringToCopy, $containerClicked);
};

export const setViewHeight = () => {
  // To set the height of the messages container
  // We use a little trick from https://css-tricks.com/the-trick-to-viewport-units-on-mobile to get real view height independent
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document and use that in user-messages.scss
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  //
};

// ########## For mobile device, so the keyboard doesn't push up footer icons ##################
// Use window.innerHeight (and innerWidth) rather than  $(window).height()
// because the latter doesn't update correctly when going to external page (within the app shell) and then back into app pages
const originalSize = window.innerWidth + window.innerHeight;

// Tells if the layout size is different than the initial load layout. Mostly used to indicate if on-screen keyboard has opened or closed
// Returns: bool
export const isResizedDifferentThanOriginal = () =>
  window.innerWidth + window.innerHeight !== originalSize;
