import { safeSetLocalStorageItem } from '../../../react-app/src/services/localStorage';
import { webViewNavigationEventService } from '../web-view-navigation-event-service';

export const setNavigationStackFromReactNativeInWebview = (previousUrlsString) => {
  // WebView loses track of previousUrls (browsing history) after reload.
  // React Native sends the previousUrls to WebView after reload and we
  // store previousUrls received from React Native in localStorage.
  safeSetLocalStorageItem('previousUrls', JSON.stringify(previousUrlsString));
  // Force WebView to reload previousUrls received from React Native into its local storage
  webViewNavigationEventService.dispatchEventToRestorePreviousUrlsInWebView();
};
