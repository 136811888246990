import throttle from 'lodash/throttle';
import { getEls } from '../utils';
import { MESSAGE_TYPES, sendMessageToReactNative } from './rn-messages';

// This scans for elements with "data-communicate-scroll-to-app" attribute set and then attaches to their scroll
// events and communicates to React Native (i.e. from the WebView JS to the RN JS -- two different processes).
// The communication happens via the postMessage() API which sends an event that gets read
// by the onMessage handler of the WebView. See PR #3091
export const disambiguateHorizontalScrollFromSwipes = () => {
  // The assumes that the elements in question have the data-communicate-scroll-to-app attribute set.
  const scrollableEls = getEls('[data-communicate-scroll-to-app]');

  const msTimeToWaitToConsiderScrollStopped = 700;

  scrollableEls.forEach((el) => {
    let timeoutId;

    const onScrollCallback = () => {
      // Prevent the accumulation of timers
      clearTimeout(timeoutId);

      sendMessageToReactNative(MESSAGE_TYPES.STARTED_HORIZONTALLY_SCROLLING);

      // We need this timeout because there is no event in JavaScript
      // for when scrolling stops.
      timeoutId = setTimeout(() => {
        sendMessageToReactNative(MESSAGE_TYPES.STOPPED_HORIZONTALLY_SCROLLING);
      }, msTimeToWaitToConsiderScrollStopped);
    };

    const maxCallFrequencyMs = 500;

    el.addEventListener(
      // By using touchmove instead of scroll, we benefit from:
      // - touchmove will be fired only on mobile devices, while scroll is applied to
      //   any scrolling action (mouse, keyboard, touch).
      // - touchmove captures scrolling intention event if the component is (horizontally) scrolled until the end, in
      //   contrast to scroll that fails to capture that usecase.
      'touchmove',
      // The throttle prevents this from being called potentially hundreds
      // of times during a single scroll.
      throttle(onScrollCallback, maxCallFrequencyMs)
    );
  });
};

const disambiguateCarouselScrollsFromSwipes = () => {
  // Our carousels use the Splide.js library and their HTML
  // structure requires a wrapper element with the class `.splide` which
  // we can use to lock-on to the carousels.
  const carouselEls = getEls('.splide');

  carouselEls.forEach((el) => {
    el.addEventListener('touchstart', () => {
      sendMessageToReactNative(MESSAGE_TYPES.STARTED_HORIZONTALLY_SCROLLING);
    });

    el.addEventListener('touchend', () => {
      sendMessageToReactNative(MESSAGE_TYPES.STOPPED_HORIZONTALLY_SCROLLING);
    });
  });
};

/* eslint-disable-next-line */
export const preventUnintentionalBackSwipes = () => {
  disambiguateHorizontalScrollFromSwipes();
  disambiguateCarouselScrollsFromSwipes();
};
