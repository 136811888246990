// PURPOSE: The idea here is that any functions we are required to call (e.g. from the React Native apps,
// or in React.js land etc.) all exist on the `global.MorphMarket` namespace. This reduces the risk for clobbering by other code
// and it also simplifies the code structure for maintaining.
//
import {
  disambiguateHorizontalScrollFromSwipes,
  preventUnintentionalBackSwipes,
} from './app/preventUnintentionalBackSwipes';
import { triggerRatingPrompt } from './app/ratingPromptUtils';
import { setCookieFromReactNativeInWebview } from './app/setCookieFromReactNativeInWebview';
import { setNavigationStackFromReactNativeInWebview } from './app/setNavigationStackFromReactNativeInWebview';
import { convertSilentConsoleErrorsIntoAlerts, onLoad } from './app/utils';
import { toggleSpinner } from './native-app/app-utils';
import registerPushToken from './push';
import { copyText } from './utils';

// This is no-op kept to support versions of the app prior to 1.2.8 without triggering Sentry errors.
// Once we are confident that all users are on 1.2.8 or later, this function can be removed.
export const addAppVersionInfo = (appVersionNumber, appBuildVersion, otaUpdateId) => {}; // eslint-disable-line no-unused-vars

// The functions in this object are only useful in React Native's WebView.
const reactNativeOnly = {
  addAppVersionInfo,
  convertSilentConsoleErrorsIntoAlerts,
  // TODO: This function will not be necessary on the interface after most users are on
  // version 1.0.7 of the app. At that point it can be removed. (It will still be
  // needed internally in the preventUnintentionalBackSwipes file.)
  disambiguateHorizontalScrollFromSwipes,
  onLoad,
  preventUnintentionalBackSwipes,
  setNavigationStackFromReactNativeInWebview,
  setCookieFromReactNativeInWebview,
  registerPushToken,
  toggleSpinner,
  triggerRatingPrompt,
};

const webOnly = {
  copyText,
};

const state = {
  attachedFunctionalityToPage: false,
};

const mmInterface = {
  ...reactNativeOnly,
  ...webOnly,
  state,
};

// The purpose of this block of code is to protect against load order issues with other
// files that add properties to the `MorphMarket` object.
if (window.MorphMarket) {
  window.MorphMarket = { ...window.MorphMarket, ...mmInterface };
} else {
  window.MorphMarket = mmInterface;
}
