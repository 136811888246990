// Without this function our developers won't know something went wrong with the
// JavaScript when using the app without an external debugging tool.
export const convertSilentConsoleErrorsIntoAlerts = (
  message,
  sourcefile,
  lineno,
  colno,
  _error
) => {
  // Note: Errors due to the JavaScript in INJECTED_JAVASCRIPT will sadly only state "Script error".
  /* eslint-disable-next-line */
  alert(
    `There was an unrescued JS error of some sort in the console and this is a system to alert
   developers about it in staging/development. Please inspect the logs and ensure this is not
   too serious. Note that the JS security model sometimes makes the error below not
   give much detail.

   Message: ${message} - Source: ${sourcefile} Line: ${lineno}:${colno}
  `
  );

  // We need to not return something truthy, because if we do, the error will get
  // swallowed and we'll see nothing in the developer tools.
  return false;
};

// In our React app SPA there are no standard browser load events, so we need another way to
// communicate that a page is ready.
export const onLoad = (callback) => {
  window.addEventListener('loadedPage', callback);
};
